// ForgotPassword.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// ForgotPassword.js
const ForgotPassword = () => {
  const [resetEmail, setResetEmail] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      await auth.sendPasswordResetEmail(resetEmail);
      toast.success("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.error("Password reset error:", error.message);
      toast.error(`Password reset error: ${error.message}`);
    }
  };
  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
        <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
          <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl text-green-600">
            Forgot Password
          </h3>
          <form onSubmit={handlePasswordReset}>
            <div className="mb-1 sm:mb-2">
              <label
                htmlFor="resetEmail"
                className="inline-block mb-1 font-medium"
              >
                Email (for password reset)
              </label>
              <input
                placeholder="john.doe@example.org"
                required
                type="text"
                className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                id="resetEmail"
                name="resetEmail"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
            <div className="mt-4 mb-2 sm:mb-4">
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide bg-red-600 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
                Reset Password
              </button>
            </div>
          </form>
          <Link
            to="/login"
            className=" text-center text-lg font-bold text-green-600 sm:text-sm hover:text-blue-500"
          >
            Back to Login
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default ForgotPassword;
