import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../pages/firebase"; // Import the auth object from your firebase.js file
import { useNavigate } from "react-router-dom";
import logo from "./tax.jpg";

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null); // User state
  const [showLogout, setShowLogout] = useState(false); // State to toggle logout button
  let navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    auth.signOut();
    navigate("/");
    setShowLogout(false);
  };

  return (
    <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl font-medium md:px-24 lg:px-8 bg-gray-50 rounded-md">
      <div className="relative flex items-center justify-between">
        <Link
          to="/"
          aria-label="Company"
          title="Company"
          className="inline-flex items-center"
        >
          <svg
            className="w-8"
            viewBox="0 0 24 24"
            strokeLinejoin="round"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            stroke="currentColor"
            fill="none"
          >
            <rect
              className="text-green-600"
              x="3"
              y="1"
              width="7"
              height="12"
            />
            <rect x="3" y="17" width="7" height="6" />
            <rect x="14" y="1" width="7" height="6" />
            <rect
              className="text-green-600"
              x="14"
              y="11"
              width="7"
              height="12"
            />
          </svg>
          <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
            ATH Tax Services
          </span>
        </Link>
        <ul className="flex items-center hidden space-x-8 lg:flex">
          <li>
            <Link
              to="/"
              aria-label="Our product"
              title="Our product"
              className=" tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/service"
              aria-label="Our product"
              title="Our product"
              className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/back"
              aria-label="Product pricing"
              title="Product pricing"
              className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Money Back Guarantee
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              aria-label="Product pricing"
              title="Product pricing"
              className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              Blogs
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              aria-label="About us"
              title="About us"
              className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
            >
              About us
            </Link>
          </li>
          <li>
            <img className="h-12" src={logo}></img>
          </li>
          {user ? (
            <>
              <li>
                <button
                  onClick={handleLogout}
                  className="inline-flex items-center justify-center h-12 px-6 0 tracking-wide bg-red-600 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  Logout
                </button>
              </li>
              <li>
                {/* Display My Account button after login */}
                <Link
                  to="/my-account"
                  className="inline-flex items-center justify-center h-12 px-6 0 tracking-wide bg-green-600 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                  aria-label="My Account"
                  title="My Account"
                >
                  My Account
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to="/login"
                  className="inline-flex items-center justify-center h-12 px-6 0 tracking-wide bg-green-600 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                  aria-label="Login"
                  title="Login"
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  className="inline-flex items-center justify-center h-12 px-6 0 tracking-wide bg-green-600 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                  aria-label="Sign up"
                  title="Sign up"
                >
                  Sign up
                </Link>
              </li>
            </>
          )}
        </ul>
        <div className="lg:hidden">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={handleMenuToggle}
          >
            <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full">
              <div className="p-5 bg-white border rounded shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <Link
                      to="/"
                      aria-label="Company"
                      title="Company"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="w-8"
                        viewBox="0 0 24 24"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        stroke="currentColor"
                        fill="none"
                      >
                        <rect
                          className="text-green-600"
                          x="3"
                          y="1"
                          width="7"
                          height="12"
                        />
                        <rect x="3" y="17" width="7" height="6" />
                        <rect x="14" y="1" width="7" height="6" />
                        <rect
                          className="text-green-600"
                          x="14"
                          y="11"
                          width="7"
                          height="12"
                        />
                      </svg>
                      <span className="ml-2 text-xl font-medium tracking-wide text-gray-800 uppercase">
                        ATH Tax Services
                      </span>
                    </Link>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul className="space-y-4">
                    <li>
                      <Link
                        to="/"
                        aria-label="Our product"
                        title="Our product"
                        className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service"
                        aria-label="Our product"
                        title="Our product"
                        className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/back"
                        aria-label="Product pricing"
                        title="Product pricing"
                        className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                      >
                        Money Back Guarantee
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        aria-label="Product pricing"
                        title="Product pricing"
                        className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                      >
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        aria-label="About us"
                        title="About us"
                        className="0 tracking-wide text-black font-medium transition-colors duration-200 hover:text-green-600"
                      >
                        About us
                      </Link>
                    </li>
                    {user ? (
                      <div>
                        <li>
                          <Link
                            to="/my-account"
                            className="inline-flex items-center justify-center h-12 px-6 0 tracking-wide bg-green-600 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            aria-label="My Account"
                            title="My Account"
                          >
                            My Account
                          </Link>
                        </li>
                        <li className="py-5">
                          <button
                            onClick={handleLogout}
                            className="  inline-flex items-center justify-center h-12 px-6 0 tracking-wide bg-red-600 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                          >
                            Logout
                          </button>
                        </li>
                      </div>
                    ) : (
                      <div>
                        <li>
                          <Link
                            to="/login"
                            className="inline-flex items-center justify-center w-full h-12 px-6 0 tracking-wide text-white bg-green-600 transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            aria-label="Login"
                            title="Login"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/register"
                            className="my-4 inline-flex items-center justify-center w-full h-12 px-6 0 tracking-wide text-white bg-green-600 transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            aria-label="Login"
                            title="Login"
                          >
                            Create An Account
                          </Link>
                        </li>
                      </div>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
