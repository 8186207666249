import React, { useEffect } from "react";

const Money = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gray-50 py-40">
      <div className="px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="max-w-screen-sm sm:text-center sm:mx-auto">
          <a
            aria-label="View"
            className="inline-block mb-5 rounded-full sm:mx-auto"
          >
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
              <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
            Money Back Guarantee
          </h2>

          <hr className="w-full my-8 border-gray-300" />
        </div>
      </div>
      <div className="grid gap-8 row-gap-10 lg:grid-cols-2 p-5">
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5 text-green-600">
            Maximum Refund Guarantee
          </h6>
          <p className="mb-3 text-m text-gray-900">
            To be eligible for the ATH Maximum Refund Guarantee, the refund
            claim must be submitted within the same calendar year in which the
            return was prepared. Additionally, the larger refund or reduced tax
            liability must not result from incomplete, inaccurate, or
            inconsistent information provided by you, decisions made by you,
            your choice to forgo claiming a deduction or credit, conflicting tax
            laws, or alterations in tax laws post January 1, 2023.
          </p>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center ">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5 text-green-600">
            No Surprise Guarantee
          </h6>
          <p className="mb-3 text-m text-gray-900">
            If we fail in delivering any of the key advantages outlined in our
            "No Surprise Guarantee"(which comprises Upfront Transparent Pricing,
            Transparent Process, Free Audit Assistance, and Free Mid-Year Care
            Check- In), you will eligible 50% discount on next year’s tax
            preparation fee when filing with ATH Tax.
          </p>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5 text-green-600">
            100% Accuracy Guarantee
          </h6>
          <p className="mb-3 text-m text-gray-900">
            If ATH Tax makes an error on your return, we will reimburse 100% for
            any resulting penalties and interest, up to $10,000 in Penalty &
            Interest charges. The ATH 100% Accuracy Guarantee applies if errors
            are not a result of incomplete or inaccurate information provided by
            you, your unsupported or incorrect positions, your decision not to
            claim a deduction or credit, conflicting tax laws, or changes in tax
            laws after January 1, 2024.
          </p>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5 text-green-600">
            100% Satisfaction Guarantee
          </h6>
          <p className="mb-3 text-m text-gray-900">
            We’re so certain you’ll be satisfied with the tax preparation
            services at our offices; you only pay us if you accept your return.
            By authorizing ATH to e-file your tax return or by taking the
            completed return to file, you are accepting the return and are
            obligated to pay all fees when due.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Money;
