import React from "react";

import HorizontalBar from "./Horizontal";

const TaxInfo = () => {
  const handleDownload = (url) => {
    // Implement download logic here, e.g., redirecting to the provided link.
    window.open(url, "_blank");
  };

  return (
    <div className="py-40 lg:px-10 p-5">
      <HorizontalBar />

      <div className="flex flex-col justify-center items-center my-8 py-6  ">
        <h1 className="text-3xl text-center font-bold p-5">
          Download Tax Information Documents
        </h1>
        <div className="flex flex-col items-center space-y-4">
          {[
            {
              year: 2023,
              url: "https://firebasestorage.googleapis.com/v0/b/celestial-air-236406.appspot.com/o/Tax_information_Form_2023%20-%20Copy.docx?alt=media&token=fc0fea49-9db5-4caa-b594-a5484f8c7611",
            },
            {
              year: 2022,
              url: "https://firebasestorage.googleapis.com/v0/b/celestial-air-236406.appspot.com/o/Tax_information_Form_2023%20-%20Copy.docx?alt=media&token=fc0fea49-9db5-4caa-b594-a5484f8c7611",
            },
            {
              year: 2021,
              url: "https://firebasestorage.googleapis.com/v0/b/celestial-air-236406.appspot.com/o/Tax_information_Form_2023%20-%20Copy.docx?alt=media&token=fc0fea49-9db5-4caa-b594-a5484f8c7611",
            },
          ].map((item) => (
            <button
              key={item.year}
              onClick={() => handleDownload(item.url, item.year)}
              className="bg-green-600 text-white px-3 py-2 w-24 rounded md:w-32 hover:bg-green-700 focus:outline-none transition duration-300 ease-in-out"
            >
              <span className="flex flex-col items-center">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANFJREFUSEvtlMENAWEQRt+6uStBEVx1INEHpdCHRAeuFKEEdzfkJSS7sr+ZPWyQ+M4z35v/m9mt6FlVz/58DeBWeGk4YFjwMP4Dwlv6vYjWwDJ8V7NgA6xee0pXNAC2wDwJ2QEL4JoFWDcE9sAkgByBGXBpq4u+gxFwAMYFyAmYAufSEBHAPs2FCKtLU82FFJUB2GxMxmVsyjiMxXjeKgvQxIW7eOVCXWyoLgDNnmfoGafUFZAyrRd9BFD672Snbwzd9oLeAdlJU3Uf2UFqsmzRHehrHBmAe1VEAAAAAElFTkSuQmCC" />
                {item.year}
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaxInfo;
