import React, { useState, useEffect } from "react";

export const Blog = () => {
  const [popupContent, setPopupContent] = useState(null);

  const handleLearnMoreClick = (content) => {
    setPopupContent(content);
  };

  const handleClosePopup = () => {
    setPopupContent(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Sample blog data (replace with your actual data)
  const blogs = [
    {
      image:
        "https://assets-global.website-files.com/600089199ba28edd49ed9587/6321fa51e590d23da7accdac_HERO%20married%20filing%20jointly%20vs%20separately-p-1080.png",
      category: "Tax",
      title: "What is Married Filing Jointly (MFJ)?",
      shortContent:
        "Married Filing Jointly 2023: In MFJ 2023 both the spouses income whether earned or unearned income can be added on Married Filing Jointly tax return, in turn a primary tax payer can claim a standard deduction amount of $27,700 on tax returns.",
      fullContent: (
        <>
          <h1>Married Filing Jointly 2023</h1>
          <p>
            In MFJ 2023, both the spouses' income, whether earned or unearned
            income, can be added on Married Filing Jointly tax return. In turn,
            a primary taxpayer can claim a standard deduction amount of $27,700
            on tax returns. Even if one spouse has income and the other does
            not, still a husband and wife can file their filing status as MFJ.
            When a married filing jointly tax return is filed, both spouses will
            be liable for taxes.
          </p>

          <h2>Standard Tax Return</h2>
          <p>
            A standard tax return is filed by resident aliens, non-resident
            aliens, green card holders, and citizens, using forms 1040, 1040SR,
            and 1040NR.
          </p>

          <h2>Conditions:</h2>
          <ul>
            <li>
              A husband and wife should have got married on any day of a tax
              year for which you are filing your tax returns.
            </li>
            <li>
              Marriage should have been recognized by any state government.
            </li>
            <li>
              Husband and Wife can file MFJ though they did not live together,
              but should not have been divorced.
            </li>
          </ul>

          <h2>How Does Standard Deduction Work For Married Filing Jointly?</h2>
          <p>
            As we learned that standard deduction reduces your taxable income,
            let’s go through the tax brackets below by which your taxable income
            is calculated for Married Filing Jointly (MFJ). In order to get
            qualified for standard deduction, a taxpayer should be eligible for
            any of the Green Card Test, Substantial Presence Test.
          </p>
          <p>
            Resident aliens and USA citizen’s taxpayers will be taxed on
            worldwide income.
          </p>

          <h2>How To Claim Dependents On Tax Returns?</h2>
          <p>
            Married taxpayers can claim a spouse, kids, and qualified dependents
            on tax return. Unmarried taxpayers can claim qualified dependents on
            tax returns. In order to claim dependents on tax returns, all the
            dependents should have SSN/ITIN/ATIN.
          </p>
          <h3>Three tests for claiming dependents on tax returns:</h3>
          <ol>
            <li>Dependent Taxpayer Test</li>
            <li>Joint Return Test</li>
            <li>Citizenship or Residency Test</li>
          </ol>

          <h2>Test for Qualifying Child:</h2>
          <ul>
            <li>Relationship Test</li>
            <li>Age Test</li>
            <li>Residency Test</li>
            <li>Support Test</li>
          </ul>

          <p>
            <strong>Note:</strong> According to the new tax amendment, a
            taxpayer cannot claim personal and Dependency Exemptions.
          </p>
        </>
      ),
    },
    {
      image:
        "https://img.freepik.com/free-vector/pensioners-healthcare-expenses-senile-patients-treatment-budget-finance-health-insurance-program-nurse-assisting-elderly-man-retired-client-vector-isolated-concept-metaphor-illustration_335657-2823.jpg?w=826&t=st=1705148681~exp=1705149281~hmac=e7aeb78fa115b1e88ea7dbc8df19daa1a04240aae280be585705883c9df8ab62",

      category: "Tax",
      title: "Health Savings Account",
      shortContent:
        "Health Savings Account (HSA) is a personal medical savings account for United States citizens who are enlisted in a high-deductible health plan (HDHP) and a salutary option for the taxpayers.",
      fullContent: (
        <>
          <p>
            Health Savings Account (HSA) is a personal medical savings account
            for United States citizens who are enlisted in a high-deductible
            health plan (HDHP) and a salutary option for the taxpayers.
          </p>
          <p>
            Although there are no permissions required from Internal Revenue
            Service (IRS) to create an HSA (Health Savings Account), because of
            the tax advantages there are specific criteria you need to qualify
            on to create a Health Savings Account (HSA) and avail the tax
            benefits. Most exciting thing about HSA is, if you continue to
            contribute on it year on year without spending anything from the
            account, your money will be accumulated and by this way such a
            corpus capital can be generated that you can actually use this on
            your retirement planning.
          </p>
          <p>
            Setting Up An Health Savings Account: Enrolling to an HSA is not at
            all difficult. It can be set up through a bank, credit unions,
            insurance companies or a licensed broker. You can create an HSA
            account with any qualified custodian. But it is very important that
            you choose well for an HSA provider, because HSA is more than only
            getting the tax advantages for your medical expenses, some HSA
            administrators even allow you for a long-term investment in mutual
            funds. The Investment options and the fees are different among
            different HSA providers, some account administrators will even force
            you to keep a minimum balance, so be wise to choose the HSA provider
            that fits your best interest and provides more supremacy to control
            your account.
          </p>
          <p>
            Rules To Qualify For Health Savings Account:
            <ul>
              <li>
                You are covered under a high deductible health plan (HDHP),
                described later, on the first day of the month.
              </li>
              <li>
                You don’t have any additional health insurance coverage except
                what is permitted under other health coverage, later.
              </li>
              <li>You aren’t enrolled in Medicare.</li>
              <li>
                Nobody else can claim you as a dependent on their tax return.
              </li>
            </ul>
          </p>
        </>
      ),
    },
    {
      image:
              "https://img.freepik.com/free-photo/business-man-calculating-finance-numbers_23-2148793750.jpg?w=740&t=st=1705148556~exp=1705149156~hmac=2019824ccb56c67caac8d50f0fb30b3e3197c311b7c06e4157f896d45180f933",
      category: "Tax",
      title: "Earned Income Tax Credit (EIC) 2022",
      shortContent:
        "Earned Income Tax Credit 2021, known as EITC or EIC, is a tax benefit for people working in the United States with low income. The taxpayer must meet specific criteria to be eligible for EIC, as it reduces taxes or may even provide a refund.",
      fullContent: (
        <>
          <p>
            In-depth details about Earned Income Tax Credit, eligibility
            criteria, and the importance of accurate tax filing.
          </p>
          <p>
            Mention of our tax consulting services and encouragement for
            taxpayers to double-check their eligibility for EIC.
          </p>
          <p>
            Eligibility Criteria:
            <ul>
              <li>Must work and have earned income.</li>
              <li>Must fall into an age group between 25 to 65 years old.</li>
              <li>Additional criteria as per tax regulations.</li>
            </ul>
          </p>
          <p>
            Common Mistakes: Common mistakes made by taxpayers regarding EIC and
            the importance of accurate filing.
          </p>
        </>
      ),
    },

    // Add more blog entries as needed
  ];

  return (
    <div className="bg-gray-50">
      <div className="px-4 py-40 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-40">
        <div className="max-w-screen-sm sm:text-center sm:mx-auto">
          <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
            Blogs
          </h2>
          <hr className="w-full my-8 border-gray-300" />
        </div>
        <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
          {/* Render Blog Entries */}
          {blogs.map((blog, index) => (
            <div
              key={index}
              className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm"
            >
              <img
                src={blog.image}
                className="object-cover w-full h-64"
                alt=""
              />
              <div className="p-5 border border-t-0">
                <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                  <span
                    className="transition-colors duration-200 text-blue-gray-900 text-green-600"
                    aria-label="Category"
                    title={blog.category}
                  >
                    {blog.category}
                  </span>
                </p>
                <div
                  onClick={() => handleLearnMoreClick(blog.fullContent)}
                  aria-label="Category"
                  title={blog.title}
                  className="cursor-pointer inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 text-green-600"
                >
                  {blog.title}
                </div>
                <p className="mb-2 text-gray-700">
                  {blog.shortContent}{" "}
                  <span
                    className="cursor-pointer inline-flex items-center font-semibold transition-colors duration-200 text-blue-400 :text-deep-purple-800"
                    onClick={() => handleLearnMoreClick(blog.fullContent)}
                  >
                    Learn more
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Popup */}
      {popupContent && (
        <div className="fixed z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 w-full h-full flex items-center justify-center overflow-auto">
          <div className="bg-white p-8 max-w-screen-md w-11/12 h-4/5 relative overflow-y-auto rounded shadow-lg">
            {/* Close button */}
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={handleClosePopup}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            {/* Popup content */}
            {popupContent}
          </div>
        </div>
      )}
    </div>
  );
};
