export const Submitted = () => {
  return (
    <div className="bg-gray-50">
      <div className="px-4 py-40  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-40">
        <div className="max-w-lg sm:text-center sm:mx-auto">
          <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
            <span className="relative inline-block">
              <span className="relative bg-white">Your</span>
            </span>{" "}
            Details Have Been Submitted successfully
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            We will get back to you within 24 to 48 hours.
          </p>
          <hr className="my-8 border-gray-300" />
          <div className="flex items-center mb-3 sm:justify-center"></div>
          <p className="max-w-xs text-xs text-gray-600 sm:text-sm sm:max-w-sm sm:mx-auto">
            Get Your Max Refund with 100% Accuracy Guaranteed.
          </p>
        </div>
      </div>
    </div>
  );
};
