import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
// Import your components
import Navbar from "./components/Navbar";
import TaxInfo from "./pages/Taxinfo";
import Login from "./pages/Login";
import Reg from "./pages/Register";
import Footer from "./components/Footer";

import { auth } from "./pages/firebase";
import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
import Money from "./pages/Back";
import Myaccount from "./pages/Myaccount";
import Appointment from "./pages/Appointment";
import { Blog } from "./pages/Blog";
import { Submitted } from "./pages/Submitted";
import Draft from "./pages/Draft";
import Final from "./pages/Final";
import Forget from "./pages/Forget";
export default function App() {
  const [user, setUser] = useState(null); // User state

  useEffect(() => {
    // Listen for Firebase Authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set user state to the logged-in user or null
    });

    return () => {
      unsubscribe(); // Unsubscribe when component unmounts
    };
  }, []);
  const isLoggedIn = () => {
    return user !== null;
  };

  return (
    <BrowserRouter>
      <div>
        <div className="fixed flex-1 w-full z-10">
          <Navbar />
        </div>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Reg />} />
          <Route path="/back" element={<Money />} />
          <Route path="/service" element={<Service />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/" element={<Home />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/submit" element={<Submitted />} />
          <Route path="/forget" element={<Forget />} />
          {isLoggedIn() && (
            <>
              <Route path="/my-account" element={<Myaccount />} />
              <Route path="/draft" element={<Draft />} />
              <Route path="/final" element={<Final />} />
              <Route path="/taxinfo" element={<TaxInfo />} />
            </>
          )}
        </Routes>

        <Footer />
      </div>
    </BrowserRouter>
  );
}
