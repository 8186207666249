import React from "react";
import { Link } from "react-router-dom";

const HorizontalBar = () => {
  return (
    <div>
      <div className="max-w-screen-sm sm:text-center sm:mx-auto">
        <a
          aria-label="View"
          className="inline-block mb-5 rounded-full sm:mx-auto"
        >
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
        </a>
        <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
          Dashboard
        </h2>

        <hr className="w-full my-8 border-gray-300" />
      </div>
      <div className="flex flex-wrap justify-between items-center bg-gray-50 p-4 my-4 rounded-md border border-gray-300">
        <Link
          to="/taxinfo"
          className="m-2 bg-green-600 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-green-700 focus:outline-none"
        >
          Download Tax Information
        </Link>
        <Link
          to="/my-account"
          className="m-2 bg-red-600 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-red-700 focus:outline-none"
        >
          Upload Documents
        </Link>
        <Link
          to="/draft"
          className="m-2 bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none"
        >
          Download Tax Draft
        </Link>
        <Link
          to="/final"
          className="m-2 bg-indigo-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-indigo-600 focus:outline-none"
        >
          Download Final Tax Documents
        </Link>
      </div>{" "}
    </div>
  );
};

export default HorizontalBar;
