import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import HorizontalBar from "./Horizontal";
import { storage, db } from "./firebase"; // Import your Firebase config
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getAuth } from "firebase/auth";
const Myaccount = () => {
  const [firstName, setFirstName] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedType, setSelectedType] = useState("individual");
  let navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    console.log("Selected Files:", selectedFiles);
    setFiles([...files, ...selectedFiles]);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check if user is authenticated
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        alert("User not authenticated");
        return;
      }

      // Specify the folder path with user's email address
      const folderPath = `user-uploads/${user.email}`;

      // Upload each file to Firebase Storage
      const uploadedFiles = await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(storage, `${folderPath}/${file.name}`);
          await uploadBytes(storageRef, file);
          const fileUrl = await getDownloadURL(storageRef);
          return { name: file.name, url: fileUrl };
        })
      );

      // Store user details, folder name, and file URLs in Firestore
      await db.collection("submitted").doc(user.email).set({
        firstName,
        selectedYear,
        selectedType,
        folderPath, // Include the folder path in Firestore
        files: uploadedFiles,
      });

      alert("Data submitted successfully!");
      navigate("/submit");
    } catch (error) {
      console.error("Error uploading files and storing data: ", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="py-40 lg:px-10 p-5 ">
      <div className="px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 "></div>{" "}
      <HorizontalBar />{" "}
      <h1 className="text-3xl text-center font-bold p-5">
        Upload Tax Documents
      </h1>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="firstName"
          >
            Full Name:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="firstName"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="year"
          >
            Select Year:
          </label>
          <select
            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="year"
            onChange={handleYearChange}
            value={selectedYear}
          >
            <option value="">Select Year</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="type"
          >
            Return Type:
          </label>
          <div className="flex">
            <label className="mr-4">
              <input
                type="radio"
                value="individual"
                checked={selectedType === "individual"}
                onChange={handleTypeChange}
              />
              Individual
            </label>
            <label>
              <input
                type="radio"
                value="business"
                checked={selectedType === "business"}
                onChange={handleTypeChange}
              />
              Business
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="file"
          >
            Upload Tax files:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="file"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          {files.map((file, index) => (
            <div key={index} className="mb-2">
              <span className="text-gray-700 text-sm">{file.name}</span>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Myaccount;
