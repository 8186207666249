import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import back from "./back.jpg";
import pic from "./pic.png";

import doc from "./doc.jpg";

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  window.scrollTo(0, 0);
  return (
    <section className="bg-gray-50 text- overflow-hidden">
      <Intro />
      <Rcontent />
      <Content /> <TaxEstimateForm />
      <Service />
      <Step />
      <Pricing />
      <Refer />
      <Lower />
    </section>
  );
}

const TaxEstimateForm = () => {
  return (
    <div className="container mx-auto">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight  sm:text-4xl md:mx-auto">
          Services At <span className="text-green-600">Zero Cost !</span>
        </h2>
      </div>

      <table className="table-auto mx-auto mt-5 w-full sm:w-4/5">
        <thead>
          <tr>
            <th className=" text-2xl border px-4 py-2">Services</th>
            <th className=" text-2xl border px-4 py-2">Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">Tax Estimate</td>
            <td className="border px-4 py-2 flex items-center ">
              <div className="m-auto">
                <span className="line-through text-red-500 ">$90</span>
                <span> &nbsp; &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Form 1040 Income Below $30,800</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$110</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Tax Experts Unlimited Consultations
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$300</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Financial Advisory Service</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$1,500</span>
                <span> &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Tax Planning for TY 2022 & 2023
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$450</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Filed Tax Returns Assessment & Examination
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$110</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">ITIN Guidance</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$150</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              FBAR & FATCA E-Filing Per Account
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$50</span>
                <span> &nbsp; &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">
              Tax Expert Support for Notices & Audits.
            </td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$300</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">W4, W9 Guidance & Assistance</td>
            <td className="border px-4 py-2 flex items-center">
              {" "}
              <div className="m-auto">
                <span className="line-through text-red-500">$140</span>
                <span> &nbsp; &nbsp;$0</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gray-50 py-40">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight  sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <span className="relative ">File With Us And </span>
          </span>{" "}
          Avail Our <span className="text-green-600">Additional Benefits</span>
        </h2>
      </div>
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0"></div>
        <div className="relative grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              Accuracy Guarantee
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Audit & Assurance
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              365 days Customer Support
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Maximum Refunds by Our High Tax Knowledge
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              100% Data Security
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              Preserve your tax returns up to 9 tax years with data security
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Covered by data protection laws
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-gray-50  text-2xl">
              100% Satisfaction Guarantee
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const Intro = () => {
  const backgroundImageUrl = `url(${back})`;

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center ">
      <div
        className="absolute   "
        style={{
          backgroundImage: backgroundImageUrl,
          backgroundSize: "cover",
          left: -80,
          right: -80,
          z: -11,
          borderRadius: "10px",
          backgroundPosition: "center",
          opacity: 0.2,
          inset: 0,
        }}
      ></div>
      <div className="mx-auto max-w-3xl text-center relative p-10 ">
        <h1 className="text-green-600 text-6xl font-extrabold  sm:text-6xl">
          ATH Tax Services INC
        </h1>

        <p className="mx-auto mt-4  font-bold max-w-xl sm:text-2xl/relaxed">
          Optimize your tax return for the highest possible refund with our
          expertise tax filers.
        </p>

        <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
          We’re ready to help you get your biggest refund
        </p>

        <div className="mt-8 flex flex-wrap justify-center gap-4">
          <a
            className="block w-full rounded border border-green-600 bg-green-600 px-12 py-3 text-m font-medium text-gray-100 hover:bg-green-700 hover:text-gray-100 focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
            href="/login"
          >
            Start Filing
          </a>

          <a
            className="block w-full rounded border bg-green-600 border-green-600 px-12 py-3 text-m font-medium text-gray-100 hover:bg-green-700 focus:outline-none focus:ring active:bg-green-700 sm:w-auto"
            href="/appointment"
          >
            Make An Appointment
          </a>
        </div>
      </div>
    </div>
  );
};

const Refer = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="p-8 rounded shadow-xl sm:p-16">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
            <h2 className="font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
              <br className="hidden md:block" />
              File Your Taxes and Earn $15 per Referral!
            </h2>
          </div>
          <div className="lg:w-1/2">
            <p className="mb-4 text-base text-gray-700">
              Are you ready to supercharge your tax filing experience? At ATH
              Services, we not only help you maximize your tax refund but also
              offer exciting rewards for sharing the joy of hassle-free tax
              filing with your friends!
            </p>
            <div className="mb-4">
              <p className="text-base text-gray-700">
                Refer a friend to ATH Services and get $15 for every successful
                referral! Your friend gets excellent tax services, and you get
                rewarded – it's a win-win!
              </p>
              {/* You can add an input field for the friend's email and a button for referral initiation here */}
            </div>
            <a
              href="/register"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-green-600 hover:text-deep-purple-800"
            >
              Get Started Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div
          data-aos="fade"
          data-aos-offset="200"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight  sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <span className="relative">Get</span>
            </span>{" "}
            Your Tax Return In{" "}
            <span className="text-green-600">3 Easy Steps</span>
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            We will ask you to fill in some basic information and upload your
            W2. You will then receive your Free Tax Draft to review before you
            decide to pay and have your taxes filed with us.
          </p>
        </div>
      </div>
      <div className="grid gap-10 lg:grid-cols-4 sm:grid-cols-2">
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in"
          data-aos-duration="1000"
        >
          <div>
            <div className="flex items-center justify-between mb-6">
              <p className="text-2xl font-bold text-green-600 ">
                Fill In Basic Info
              </p>
              <svg
                className="w-6 text-green-600  transform rotate-90 sm:rotate-0"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <line
                  fill="none"
                  strokeMiterlimit="10"
                  x1="2"
                  y1="12"
                  x2="22"
                  y2="12"
                />
                <polyline
                  fill="none"
                  strokeMiterlimit="10"
                  points="15,5 22,12 15,19 "
                />
              </svg>
            </div>
            <p className="text-gray-500 font-bold">
              Quickly share your basic details with us.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in"
          data-aos-duration="800"
        >
          <div>
            <div className="flex items-center justify-between mb-6">
              <p className="text-2xl font-bold text-green-600">
                Secure W2 Upload
              </p>
              <svg
                className="w-6 text-green-600 transform rotate-90 sm:rotate-0"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <line
                  fill="none"
                  strokeMiterlimit="10"
                  x1="2"
                  y1="12"
                  x2="22"
                  y2="12"
                />
                <polyline
                  fill="none"
                  strokeMiterlimit="10"
                  points="15,5 22,12 15,19 "
                />
              </svg>
            </div>
            <p className="text-gray-500 font-bold">
              Safely upload your W2 for a tailored tax draft.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <div>
            <div className="flex items-center justify-between mb-6">
              <p className="text-2xl font-bold text-green-600">
                Get Your Quote
              </p>
              <svg
                className="w-6 text-green-600 transform rotate-90 sm:rotate-0"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <line
                  fill="none"
                  strokeMiterlimit="10"
                  x1="2"
                  y1="12"
                  x2="22"
                  y2="12"
                />
                <polyline
                  fill="none"
                  strokeMiterlimit="10"
                  points="15,5 22,12 15,19 "
                />
              </svg>
            </div>
            <p className="text-gray-500 font-bold">
              Receive your Free Tax Draft for thorough review. Only pay when
              you're ready to file.
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in"
          data-aos-duration="400"
        >
          <div>
            <div className="flex items-center justify-between mb-6">
              <p className="text-2xl font-bold text-green-600">Success</p>
              <svg
                className="w-8 text-green-600"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <polyline
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points="6,12 10,16 18,8"
                />
              </svg>
            </div>
            <p className="text-gray-500 font-bold">
              Thank you for choosing us for your stress-free tax Filing
              experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Pricing = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      {" "}
      <div
        data-aos="fade"
        data-aos-offset="200"
        data-aos-easing="ease-in"
        data-aos-duration="600"
      >
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-600 uppercase rounded-full bg-white">
              Our Pricing
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <span className="relative ">How Do </span>
            </span>{" "}
            You Want To Get Started With{" "}
            <span className="text-green-600">Your Tax Pro?</span>
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Access legitimate tax returns immediately upon choosing our
            services, no commitments required.
          </p>
        </div>
      </div>
      <div className="grid max-w-md gap-10 row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
        <div
          data-aos="flip-right"
          data-aos-offset="200"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-green-600 border border-green-600 rounded shadow-sm sm:items-center hover:shadow">
            <div className="text-center text-gray-50">
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-3xl font-bold"> Federal Filing</div>
              </div>
              <div className="mt-2 space-y-3">
                {" "}
                <div className="text-lg font-semibold">Starting at </div>
                <div className="text-3xl"> $59</div>
                <div>Free tax estimate.</div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="flip-down"
          data-aos-offset="200"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <div className="relative flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded shadow-sm sm:items-center hover:shadow border-deep-purple-accent-400">
            <div className="absolute inset-x-0 top-0 flex justify-center -mt-3"></div>
            <div className="text-center text-black">
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-3xl font-bold"> State Filing </div>
              </div>
              <div className="mt-2 space-y-3">
                {" "}
                <div className="text-lg font-semibold">Starting at </div>
                <div className="text-3xl"> $30</div>
                <div>Free tax estimate.</div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <div className=" border-green-600 bg-green-600 flex flex-col justify-between p-8 transition-shadow duration-300 bg-green-00 border rounded shadow-sm sm:items-center hover:shadow">
            <div className="text-center text-gray-50">
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-3xl font-bold"> Business Filing </div>
              </div>
              <div className="mt-2 space-y-3">
                {" "}
                <div className="text-lg text-gray-50 font-semibold">
                  Starting at{" "}
                </div>
                <div className="text-3xl"> $250</div>
                <div>Free tax expert consultation </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Lower = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-screen-sm sm:text-center sm:mx-auto">
        <a
          href="/"
          aria-label="View"
          className="inline-block mb-5 rounded-full sm:mx-auto"
        >
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
            <svg
              className="w-12 h-12 text-deep-purple-accent-400"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
        </a>
        <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
          100% Guarantee
        </h2>
        <p className="text-base text-gray-700 md:text-lg sm:px-4">
          Get Your Max Refund with 100% Accuracy Guaranteed.
        </p>
        <hr className="w-full my-8 border-gray-300" />
      </div>
    </div>
  );
};

export const Content = () => {
  return (
    <div className=" px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20  ">
      <div className="grid gap-10 lg:grid-cols-2">
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <div className="flex py-20 flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg max-md:py-2">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight   sm:text-4xl sm:leading-none">
                Over <span className="text-green-600">Half A Million</span>{" "}
                Clients Have Made ATH Tax Services, Inc. Their Top Choice
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Upload your W2s and fill in some basic information about
                yourself and we will send you a Free Tax Draft in 24 Hours to
                Review before you decide to file!
              </p>
            </div>
            <div>
              <a
                href="/"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-green-600 hover:text-deep-purple-800"
              >
                Upload
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="px-3">
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in"
              data-aos-duration="600"
            >
              <img className="h-96" src={doc} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Rcontent = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex items-center justify-start -mx-4 lg:pl-8">
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in"
            data-aos-duration="600"
          >
            <img className="h-96" src={pic} alt="" />
          </div>

          <div className="px-3"></div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-easing="ease-in"
          data-aos-duration="600"
        >
          <div className="flex py-20 flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg max-md:py-2">
            <div className="max-w-xl mb-6">
              <p className="text-red-500 text-3xl">Advance Refund</p>
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight  sm:text-4xl sm:leading-none">
                Get up to <span className="text-green-600">$4,000 </span> Refund
                instantly
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                You could get a Refund Advance loan   up to $4,000 within one
                day from IRS acceptance you file your taxes. Plus, enjoy no
                interest, no loan fees, and no impact to your credit score.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
