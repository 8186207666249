import React, { useEffect, useState } from "react";
import about from "./aboutus.jpg";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="bg-gray-50 py-40">
      <div className="px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="max-w-screen-sm sm:text-center sm:mx-auto">
          <a
            aria-label="View"
            className="inline-block mb-5 rounded-full sm:mx-auto"
          >
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
              <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
            About Our Firm
          </h2>

          <hr className="w-full my-8 border-gray-300" />
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <p className="text-base text-gray-700 md:text-lg">
                ATH Tax Services, Inc. (AthTax.com) is a full-service tax
                consulting firm offering tax return preparation, tax planning,
                tax compliance, accounting, bookkeeping, business advisory,
                business incorporation, and payroll. We offer services to a wide
                variety of clients ranging from individuals too small to
                medium-sized businesses since 2015.
              </p>
            </div>
            <div>
              <div>
                <p className="text-base text-gray-700 md:text-lg">
                  We understand the challenges of running a small business,
                  whether it’s sorting through six-thousand pages of tax code,
                  writing a comprehensive financial statement, establishing a
                  line of credit, representing you at a tax audit or other
                  important business meetings, or teaming up with other
                  qualified professionals to help make your business a success
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src={about}
              alt=""
            />
          </div>
        </div>
      </div>

      <div>
        {" "}
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="grid row-gap-8 sm:grid-cols-3 ">
            <div class="text-center p-10">
              <h6 class="text-5xl font-bold text-green-600">3</h6>
              <p class="font-bold">Year's In The Business</p>
            </div>
            <div class="text-center p-10">
              <h6 class="text-5xl font-bold text-green-600">65,000+</h6>
              <p class="font-bold">Happy Clients</p>
            </div>
            <div class="text-center p-10">
              <h6 class="text-5xl font-bold text-green-600">209</h6>
              <p class="font-bold">Expert Accountants</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
