import React, { useEffect, useState } from "react";
import HorizontalBar from "./Horizontal";
import { db, auth } from "./firebase";

const Draft = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getCurrentUserEmail = async () => {
      const user = auth.currentUser;

      if (user) {
        const userEmail = user.email;

        // Fetch the specific document from Firebase Firestore using the email as the document ID
        const draftDoc = db.collection("drafts").doc(userEmail);

        draftDoc.get().then((doc) => {
          if (doc.exists) {
            // If the document exists, add it to the items array
            setItems([doc.data()]);
            console.log("Fetched Draft:", doc.data());
          } else {
            // If the document doesn't exist, set items to an empty array
            setItems([]);
            console.log("No draft found for the current user.");
          }
        });
      }
    };

    getCurrentUserEmail();
  }, []); // Run this effect only once when the component mounts

  return (
    <div className="py-40 lg:px-10 p-5">
      <div className="px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 "></div>{" "}
      <HorizontalBar />
      <h1 className="text-3xl text-center font-bold p-5">
        List Of Draft Tax Documents
      </h1>
      <div className=" h-screen">
        {items.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-300 mt-4">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Download</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b  text-center">
                    {index + 1}
                  </td>
                  <td className="py-2 px-4 border-b  text-center">
                    {item.name}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <button
                      onClick={() => (window.location.href = item.url)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-800 text-center mt-4">No records found.</p>
        )}
      </div>
    </div>
  );
};

export default Draft;
