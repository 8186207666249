import React, { useEffect } from "react";
const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gray-50 py-40">
      <div className="px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="max-w-screen-sm sm:text-center sm:mx-auto">
          <a
            aria-label="View"
            className="inline-block mb-5 rounded-full sm:mx-auto"
          >
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
              <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-green-600 sm:text-4xl sm:leading-none">
            Our Services
          </h2>

          <hr className="w-full my-8 border-gray-300" />
        </div>
      </div>
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0"></div>
        <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-white  text-2xl">
              Tax Planning & Advisory Services
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Tax Return Services
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-white  text-2xl">
              Tax Consulting Services
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Tax Preparation Service
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              ITIN Guidance Services
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-white  text-2xl">
              Extension Filing Services
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-gray-50 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-black  text-2xl">
              Business Tax Returns Planning & Preparation
            </p>
          </div>
          <div className="px-10 py-20 text-center transition duration-300 transform bg-green-600 rounded shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl">
            <p className="font-semibold text-white  text-2xl">
              Payroll Processing Services
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
